import Loading from "@components/common/Loading/Loading";
import { ROUTES } from "@constants/routes";
import useMe from "@data/backoffice/Auth/Query/GetMe/GetMeQuery";
import useLogin from "@data/backoffice/Registration/Mutations/LogIn/LogIn";
import { isClientSide } from "@utils/isClientSide";
import { removeItem, saveItem } from "@utils/LocalStorage";
import { signOut, signout, useSession } from "next-auth/client";
import { useRouter } from "next/router";
import React, { createContext, useContext } from "react";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
import { storageResetTimer } from "@providers/IdleTimerLocalProvider/IdleTimerLocalProvider";
interface IAuthContext {
  login: (values: any) => Promise<boolean>;
  logout: () => void;
  me: any;
  canLoad: boolean;
  loadingMe?: boolean;
}

const AuthContext = createContext<IAuthContext | null>(null);

function AuthProvider({ children }) {
  // Fetch authenticated user
  const { me, canLoad, mutate: mutateMe, loadingMe } = useMe();
  // Login
  const { mutate: mutateLogin } = useLogin();

  return (
    <AuthContext.Provider
      value={{
        async login(values) {
          //get token from api
          const result = await mutateLogin({ data: values });

          if (result?.me) {
            const { accessToken } = result;
            saveItem("token", `Bearer ${accessToken}`);
            await mutateMe();
            return true;
          }
          return false;
        },
        logout() {
          signOut();
          removeItem("token");
          removeItem("google");
          mutateMe();
          saveItem(storageResetTimer, Date.now())
          return;
        },
        me,
        canLoad,
        loadingMe,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth(): IAuthContext | null {
  return useContext(AuthContext) as IAuthContext;
}




function withAuth(WrappedComponent, shouldRedirect = true) {
  return (props) => {
    const LoadingComponent = () => {
      return (
        <div className="m-auto" >
          <Loading label={""} />
        </div>
      );

    }
    const router = useRouter();
    const { me, canLoad } = useAuth() as {me: any, canLoad: boolean};
    // If first authentication check is still pending
    if (!canLoad)
      return (
        <LoadingComponent />
      );


    // if user is logged in return component with logged in user

    if (me) return <WrappedComponent {...props} loggedInUser={me} />;

    // checks whether we are on client / browser or server.
    if (isClientSide()) {
      router.replace({
        pathname: ROUTES.AUTHENTICATION.SIGN_IN,
        query: shouldRedirect ? { redirectTo: router.pathname } : undefined,
      });
      return (
        <LoadingComponent />
      );
    }

    return null;
  };
}

export { AuthProvider, useAuth, withAuth };
